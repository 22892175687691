import React from 'react'
import { useParams } from 'react-router-dom';
import LessonCard from './LessonCard';

function Lesson() {
    const { param1, param2 } = useParams();
    const cardData = [
      {
        title: 'Додавання',
        image: 'path/to/algebra.jpg',
        subject: 'Про предмет',
        description: 'Опис предмету',
        link: '/study/algebra/11Class'
      },
      {
        title: 'Геометрія',
        image: 'path/to/geometry.jpg',
        subject: 'Про предмет',
        description: 'Опис предмету',
        link: '/study/algebra/10Class'
      },
      {
          title: 'Фізика',
          image: 'path/to/physics.jpg',
          subject: 'Про предмет',
          description: 'Опис предмету',
          link: '/study/algebra/9Class'
        },
        {
          title: 'Хімія',
          image: 'path/to/chemistry.jpg',
          subject: 'Про предмет',
          description: 'Опис предмету',
          link: '/study/algebra/8Class'
        },
      // Додайте більше об'єктів за потреби
    ];


    return (
      <div>
        <p>Параметр 1: {param1}</p>
        <p>Параметр 2: {param2}</p>
        <div className="small-card-container">
                {cardData.map((card, index) => (
                <LessonCard 
                    key={index}
                    title={card.title}
                    image={card.image}
                    subject={card.subject}
                    description={card.description}
                    tema={param2}
                    predmet={param1}
                />
                ))}
            </div>
      </div>
    );
}

export default Lesson