import React, { useEffect, useState } from 'react';
import upload from '../uploadFile';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebaseConfig';
import './User.css'; // Підключіть CSS файл для стилів

function User({ userinf }) {
  const [image, setImage] = useState('');
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [lastname, setLastname] = useState('');
  const [age, setAge] = useState('');
  const [uid, setUid] = useState('');
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    setImage(userinf.img);
    setName(userinf.username);
    setSurname(userinf.surname);
    setLastname(userinf.lastname);
    setAge(userinf.age);
    setUid(userinf.id);
  }, [userinf]);

  const handleImageChange = async (event) => {
    if (event.target.files[0]) {
      try {
        const imgUrl = await upload(event.target.files[0]);
        await updateDoc(doc(db, "users", uid), {
          img: imgUrl
        });
        setImage(imgUrl);
      } catch (err) {
        console.log(err.message);
      }
    }
  };

  const handleSave = async (event) => {
    event.preventDefault();
    try {
      await updateDoc(doc(db, "users", uid), {
        username: name,
        surname: surname,
        lastname: lastname,
        age: age
      });
      setEditMode(false);
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <div className="profile-container">
      <div className="profile-card">
        <div className="profile-photo">
          <img src={image} alt="User" />
          <input type="file" onChange={handleImageChange} id="upload-photo" hidden />
          <label htmlFor="upload-photo" className="upload-button">Завантажити фото</label>
        </div>
        <div className="profile-info">
          {editMode ? (
            <form onSubmit={handleSave} className="edit-form">
            <div className="form-group">
              <label htmlFor="name">Ім'я</label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Ім'я"
              />
            </div>
            <div className="form-group">
              <label htmlFor="surname">Прізвище</label>
              <input
                type="text"
                id="surname"
                value={surname}
                onChange={(e) => setSurname(e.target.value)}
                placeholder="Прізвище"
              />
            </div>
            <div className="form-group">
              <label htmlFor="lastname">По-батькові</label>
              <input
                type="text"
                id="lastname"
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
                placeholder="По-батькові"
              />
            </div>
            <div className="form-group">
              <label htmlFor="age">Вік</label>
              <input
                type="text"
                id="age"
                value={age}
                onChange={(e) => setAge(e.target.value)}
                placeholder="Дата народження"
              />
            </div>
            <button type="submit" className="save-button">Зберегти</button>
            <button type="button" className="cancel-button" onClick={() => setEditMode(false)}>Скасувати</button>
          </form>
          ) : (
            <>
              <h2>{userinf.username}</h2>
              <h4>Прізвище: {userinf.surname}</h4>
              <h4>По-батькові: {userinf.lastname}</h4>
              <h4>Вік: {userinf.age}</h4>
              <h4>Email: {userinf.email}</h4>
              <button className="edit-button" onClick={() => setEditMode(true)}>Редагувати</button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default User;