import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import SmallCard from './SmallCard';
import { db } from '../../firebaseConfig';
import { arrayUnion, collection, doc, getDoc, getDocs, onSnapshot, query, setDoc, updateDoc, where} from 'firebase/firestore';

function Tema() {

    const { param } = useParams();
    const [cardData, setCardData] = useState([]); 


      const getStudy = async ()=>{
        try{
          const temaref = collection(db,'study')
          const q = query(temaref, where("Href", "==", param));
          const querySnapshot = await getDocs(q);
          let a = []
          querySnapshot.forEach((docs)=>{
            a.push(docs.data())
          }) 

          const temaref2 = collection(db,'tema')
          const qq = query(temaref2, where("study", "==", a[0].study));
          const querySnapshot2 = await getDocs(qq);

          if(querySnapshot){
            let b = []
            querySnapshot2.forEach((docs)=>{
              b.push({
                title: docs.data().tema,
                study: docs.data().study,
                img: docs.data().img,
                subject: 'Про предмет',
                description: docs.data().about,
                link: docs.data().Href,
                priceSolo: docs.data().priceSolo,
                priceGroup: docs.data().priceGroup,
              })
            }) 
            setCardData(b)
          }
          
        }catch(err){
          console.log(err)
        }
      }
      
      useEffect(() => {
        getStudy()
        
      }, []);
    return (
        <div>
            <div className="small-card-container">
                {cardData.map((card, index) => (
                <SmallCard 
                    key={index}
                    title={card.study + " " + card.title}
                    img={card.img}
                    subject={card.subject}
                    description={card.description}
                    priceGroup={card.priceGroup}
                    priceSolo={card.priceSolo}
                    link={"study/"+ param + "/" + card.link}
                    tema={param}
                />
                ))}
            </div>
        </div>
    )
}

export default Tema