import React, {useEffect, useState} from 'react'
import User from './user';
import { Login, Register } from './LoginRegister';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from '../../firebaseConfig';
import { collection, getDocs, query, where } from 'firebase/firestore';

function ProfilAuthReg() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userInf, setuserInf] = useState({});


    useEffect(()=>{
      getUser()
    }, [])

    const getUser = async ()=>{
      const unSub = onAuthStateChanged(auth, async (user)=>{
        if(user == null){
          setIsAuthenticated(false);
          setuserInf({})
        }else{
          try{  
            const temaref2 = collection(db,'users')
            const qq = query(temaref2, where("id", "==", user.uid));
            const querySnapshot2 = await getDocs(qq);
  
            if(querySnapshot2){
              setuserInf(querySnapshot2.docs[0].data())
            }
            setIsAuthenticated(true);
          }catch(err){
            console.log(err)
          }
        }
      })
  
      return unSub
      
    }

  const handleLogin = () => {
    // Логіка для авторизації користувача
    setIsAuthenticated(true);
  };

  const handleRegister = () => {
    // Логіка для реєстрації користувача
    setIsAuthenticated(true);
  };

  // const handleLogout = () => {
  //   // Логіка для виходу з аккаунту
  //   const auth = getAuth();
  //   signOut(auth).then(() => {
  //       // Sign-out successful.
  //     }).catch((error) => {
  //       // An error happened.
  //     });
  //   setIsAuthenticated(false);
  // };

  return (
    <div className="app-container">
      {isAuthenticated ? (
        <div>
          <User userinf = {userInf} />
          {/* <button onClick={handleLogout}>Вийти</button> */}
        </div>
      ) : (
        <div>
          <Login onLogin={handleLogin} />
          <Register onRegister={handleRegister} />
        </div>
      )}
    </div>
  );
}

export default ProfilAuthReg