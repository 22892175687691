import { onAuthStateChanged } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { auth, db } from '../../firebaseConfig';
import { arrayUnion, collection, doc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

function SoloLesson() {
    const navigate = useNavigate();
    const { param1, param2 } = useParams();
    const [formData, setFormData] = useState({
      email: '',
      tema: '',
      study: '',
      name: '',
      time: '',
      timeGroup: '',
      lessonCount: 1,
      lessonDuration: '1',
    });
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
  
  
      try {
        // Отримуємо посилання на документ
        const docRef = doc(db, 'group', "soloUser");
        // Оновлюємо документ, додаючи новий елемент у масив
        await updateDoc(docRef, {
          
          users: arrayUnion({ ...formData, study: param1, tema: param2})
        });
        alert("Ви успішно подали заявку, скоро з вами зв'яжуться");
        console.log('Дані успішно додані в масив');
      } catch (error) {
        console.error('Помилка додавання даних у масив: ', error);
      }
      // Обробка відправки даних
      console.log(formData);
    };
  
  
    useEffect(() => {
      getUser();
    }, []);
  
    const getUser = async () => {
      const unSub = onAuthStateChanged(auth, async (user) => {
        if (user == null) {
          return
        } else {
          try {
            const temaref2 = collection(db, 'users');
            const qq = query(temaref2, where('id', '==', user.uid));
            const querySnapshot2 = await getDocs(qq);
  
            if (querySnapshot2) {
              setFormData({ ...formData, email: querySnapshot2.docs[0].data().email, name: querySnapshot2.docs[0].data().username });
            }
          } catch (err) {
            console.log(err);
          }
        }
      });
    };
  
    return (
      <div className="group-form-container">
        <form onSubmit={handleSubmit} className="group-form">
          <div className="form-group">
            <p>{param2}</p>
            <label>Пошта:</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Ім'я:</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Бажаний час для початку заняття (год:хв - год:хв):</label>
            <input
              type="text"
              name="time"
              value={formData.time}
              onChange={handleChange}
              placeholder="e.g., 09:00 - 17:00"
              required
            />
          </div>
          <div className="form-group">
            <label>Країна з якої ви будете займатись (Це для переведення часу):</label>
            <input
              type="text"
              name="timeGroup"
              value={formData.timeGroup}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Кількість бажаних занять в неділю:</label>
            <select
              name="lessonCount"
              value={formData.lessonCount}
              onChange={handleChange}
              required
            >
              {[1, 2, 3, 4, 5, 6, 7].map(count => (
                <option key={count} value={count}>{count}</option>
              ))}
              <option value="one-time">Одне заняття не на постійній основі</option>
            </select>
          </div>
          <div className="form-group">
            <label>Тривалість заняття:</label>
            <select
              name="lessonDuration"
              value={formData.lessonDuration}
              onChange={handleChange}
              required
            >
              <option value="1">1 година</option>
              <option value="2">2 години</option>
            </select>
          </div>
          <button type="submit">Записатись на заняття</button>
        </form>
      </div>
    );
}

export default SoloLesson