import React from 'react'
import { useNavigate } from 'react-router-dom';


function LessonCard({ title, image, subject, description, tema, predmet }) {
  const navigate = useNavigate();

  const soloLesson = async ()=>{
    navigate(`/order/${predmet}/${tema}/${title}`);
  }

  const groupLesson = async ()=>{
    navigate(`/group/${predmet}/${tema}/${title}`);
  }

  return (
    <div className="small-card">
      <h2>{title}</h2>
      <img src={image} alt={title} />
      <h4>{subject}</h4>
      <p>{description}</p>
      <button onClick={soloLesson}>Заняття для одного</button><br/>
      <button onClick={groupLesson}>Заняття для групи</button><br/>
    </div>
  )
}

export default LessonCard