import React from 'react'

function Home() {
  const handleRedirect = () => {
    window.location.href = 'http://localhost:3000/lessonOrder'; // Replace with your desired URL
  };

  return (
    <div className="home-container">
      <h1>Ласкаво просимо на наш освітній портал</h1>
      <button onClick={handleRedirect} className="redirect-button">
        Записатись на заняття
      </button>
      <p className="intro-text">
        Ми створили цей сайт для того, щоб зробити якісну освіту доступною для кожного. Наша мета – надавати освітні послуги за низькими цінами без втрат для репетиторів.
      </p>
      <div className="features">
        <div className="feature">
          <h2>Низькі ціни</h2>
          <p>
            Ми прагнемо забезпечити доступ до знань для всіх охочих. Наші ціни настільки низькі, що навчання стає доступним кожному, незалежно від фінансового становища.
          </p>
          <h2>
            Ціна заняття для одного - 250грн.
          </h2>
          <h2>
            Ціна заняття в групі - ціна заняття -20% для двох, -30% для трьох, -40% для чотирьох, -50% для пятьох. Наприклад, якщо вас буде четверо, то ціна для кожного буде 150грн.
          </h2>
        </div>
        <div className="feature">
          <h2>Висока якість освіти</h2>
          <p>
            Незважаючи на низькі ціни, ми гарантуємо високу якість навчання. Наші репетитори – професіонали у своїй галузі, які готові поділитися своїми знаннями та досвідом.
          </p>
        </div>
        <div className="feature">
          <h2>Мета сайту</h2>
          <p>
            Наша мета – створити освітній простір, де кожен може отримати знання за доступною ціною, а репетитори можуть знайти собі студентів без втрат для себе.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Home