import React from 'react';

const Card = ({ title, image, subject, description, link }) => {
  return (
    <div className="card">
      <h2>{title}</h2>
      <img src={image} alt={title} />
      <h4>{subject}</h4>
      <p>{description}</p>
      <a href={"study/" + link} class="study-link">Перейти до вибору заннять</a>
    </div>
  );
};

export default Card;