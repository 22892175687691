import React, { useState, useEffect } from 'react';
import { db } from '../../firebaseConfig';
import { arrayUnion, collection, doc, getDoc, getDocs, onSnapshot, query, setDoc, updateDoc, where} from 'firebase/firestore';
import upload from '../uploadFile';


function Form1({ onSubmit }) {
  const [image, setImage] = useState({
    file:null,
    url: ""
  });
  
    const handleImageChange = (event) => {
      if(event.target.files[0]){
        setImage({
          file: event.target.files[0],
          url: URL.createObjectURL(event.target.files[0])
        })
      }
    };
  
    const handleSubmit = async (event) => {
      event.preventDefault();
      const formData = new FormData(event.target)
      const {name, about, Href} = Object.fromEntries(formData)
      const imgUrl = await upload(image.file) 

      try {
        await setDoc(doc(db,'study', name),{
            study: name,
            about,
            img:imgUrl,
            Href
        });

        await updateDoc(doc(db,"lessons", 'study'), {
          studies: arrayUnion({
            name: name,
            Href
          })
        })

        console.log("All good")
    } catch (error) {
        console.log(error)
        console.error(error.message)
    }
      // Логіка для першої форми
      onSubmit();
    };
  
    return (
      <div className="form-container">
        <h2>Форма 1</h2>
        <form onSubmit={handleSubmit}>
          <label>
            Name:
            <input type="text" name="name" required />
          </label>
          <label>
            About:
            <input type="text" name="about" required />
          </label>
          <label>
            Href
            <input type="text" name="Href" required />
          </label>
          <label>
            Фото:
            <input type="file" onChange={handleImageChange} />
          </label>
          {image.file && <img src={image.file} alt="Preview" className="image-preview" />}
          <button type="submit">Надіслати</button>
        </form>
      </div>
    );
  }
  
  function Form2({ onSubmit }) {
    const [image, setImage] = useState('');
    const [options, setOptions] = useState([]);


    const getStudy = async ()=>{
      try{
        const q = doc(db,'lessons', "study");
        const querySnapshot = await getDoc(q);
        const data = querySnapshot.data().studies
        console.log(data)
        setOptions(data)
      }catch(err){
        console.log(err)
      }
    }
    
    useEffect(() => {
      getStudy()
      
    }, []);
  
    const handleImageChange = (event) => {
      if(event.target.files[0]){
        setImage({
          file: event.target.files[0],
          url: URL.createObjectURL(event.target.files[0])
        })
      }
    };

    const handleSubmit = async (event) => {
      event.preventDefault();
      const formData = new FormData(event.target)
      const {name, about, priceSolo, priceGroup, Href, select1} = Object.fromEntries(formData)
      const imgUrl = await upload(image.file) 

      try {
        await setDoc(doc(db,'tema', Href),{
            tema: name,
            about,
            img:imgUrl,
            priceSolo,
            priceGroup,
            Href,
            study:select1
        });
        console.log("All good")
    } catch (error) {
        console.log(error)
        console.error(error.message)
    }
      onSubmit();
    };
  
    return (
      <div className="form-container">
        <h2>Форма 2</h2>
        <form onSubmit={handleSubmit}>
          <label>
            Name
            <input type="text" name="name" required />
          </label>
          <label>
            About
            <input type="text" name="about" required />
          </label>
          <label>
            Price solo
            <input type="text" name="priceSolo" required />
          </label>
          <label>
            Price group
            <input type="text" name="priceGroup" required />
          </label>
          <label>
            Href
            <input type="text" name="Href" required />
          </label>
          <label>
            Вибір:
            <select name="select1" required>
              <option value="">Оберіть варіант</option>
              {options.map((option, index) => (
                <option key={index} value={option.name}>{option.name}</option>
              ))}
            </select>
          </label>
          <label>
            Фото:
            <input type="file" onChange={handleImageChange} />
          </label>
          {image && <img src={image} alt="Preview" className="image-preview" />}
          <button type="submit">Надіслати</button>
        </form>
      </div>
    );
  }
  
  function Form3({ onSubmit }) {
    const [image, setImage] = useState('');
    const [options2, setOptions2] = useState([]);
    const [options3, setOptions3] = useState([]);
  
    const getStudy = async ()=>{
      try{
        const q = doc(collection(db,'lessons'), "study");
        const querySnapshot = await getDoc(q);
        const data = querySnapshot.data()
        setOptions3(data.studies)
      }catch(err){
        console.log(err)
      }
    }

    const handleSelect4Change = async (e)=>{
      try{
        const selectedValue = e.target.value;
        const temaref = collection(db,'tema')
        const q = query(temaref, where("study", "==", selectedValue));
        const querySnapshot = await getDocs(q);
        
        if(querySnapshot){
          let a = []
          querySnapshot.forEach((docs)=>{
            a.push(docs.data())
          })
          setOptions2(a)
        }
        
      }catch(err){
        console.log(err)
      }

    }

    useEffect(() => {
      getStudy()
      
    }, []);
  
    const handleImageChange = (event) => {
      if(event.target.files[0]){
        setImage({
          file: event.target.files[0],
          url: URL.createObjectURL(event.target.files[0])
        })
      }
    };
  
    const handleSubmit = async (event) => {
      event.preventDefault();
      const formData = new FormData(event.target)
      const {name, about, priceSolo, priceGroup, Href, select2, select4} = Object.fromEntries(formData)
      const imgUrl = await upload(image.file) 

      try {
        await setDoc(doc(db,'lesson', Href),{
            tema: name,
            about,
            img:imgUrl,
            priceSolo,
            priceGroup,
            Href,
            study:select2,
            predmet: select4
        });

        console.log("All good")
    } catch (error) {
        console.log(error)
        console.error(error.message)
    }
      onSubmit();
    };
  
    return (
      <div className="form-container">
        <h2>Форма 3</h2>
        <form onSubmit={handleSubmit}>
          <label>
            Name
            <input type="text" name="name" required />
          </label>
          <label>
            About
            <input type="text" name="about" required />
          </label>
          <label>
            Price solo
            <input type="text" name="priceSolo" required />
          </label>
          <label>
            Price group
            <input type="text" name="priceGroup" required />
          </label>
          <label>
            Href
            <input type="text" name="Href" required />
          </label>
          <label>
            Предмет:
            <select name="select4" required onChange={handleSelect4Change}>
              <option value="">Оберіть варіант</option>
              {options3.map((option, index) => (
                <option key={index} value={option.name}>{option.name}</option>
              ))}
            </select>
          </label>
          <label>
            Тема:
            <select name="select2" required>
              <option value="">Оберіть варіант</option>
              {options2.map((option, index) => (
                <option key={index} value={option.tema}>{option.tema}</option>
              ))}
            </select>
          </label>
          <label>
            Фото:
            <input type="file" onChange={handleImageChange} />
          </label>
          {image && <img src={image} alt="Preview" className="image-preview" />}
          <button type="submit">Надіслати</button>
        </form>
      </div>
    );
  }

function AddLesson() {
  const handleForm1Submit = () => {
    console.log('Форма 1 надіслана');
  };

  const handleForm2Submit = () => {
    console.log('Форма 2 надіслана');
  };

  const handleForm3Submit = () => {
    console.log('Форма 3 надіслана');
  };

  return (
    <div className="app-container">
      <Form1 onSubmit={handleForm1Submit} />
      <Form2 onSubmit={handleForm2Submit} />
      <Form3 onSubmit={handleForm3Submit} />
    </div>
  );
}

export default AddLesson;
