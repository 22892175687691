import React, { useState, useEffect } from 'react'
import Card from './Card';
import { db } from '../../firebaseConfig';
import {collection, getDocs} from 'firebase/firestore';


function Study() {
  const [cardData, setCardData] = useState([]);


  const getStudy = async ()=>{
    try{
      const q = collection(db,'study')
      const querySnapshot = await getDocs(q);
      let a = []
      querySnapshot.forEach((docs)=>{
        a.push({link: docs.data().Href,
          title: docs.data().study,
          image: docs.data().img,
          subject: 'Про предмет:',
          description: docs.data().about,
        })
      })
      setCardData(a)
    }catch(err){
      console.log(err)
    }
  }
  
  useEffect(() => {
    getStudy()
    
  }, []);





  return (
    <div className="card-container">
        {cardData.map((card, index) => (
          <Card 
            key={index}
            title={card.title}
            image={card.image}
            subject={card.subject}
            description={card.description}
            link={card.link}
            tema={card.tema}
          />
        ))}
    </div>
  )
}

export default Study