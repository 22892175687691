import React from 'react'
import OrderCard from './OrderCard';
import { useParams } from 'react-router-dom';

function Order() {
  const { param1, param2, param3 } = useParams();
  const orders = [
    {
      firstName: 'Іван',
      lastName: 'Іваненко',
      middleName: 'Іванович',
      subject: param1,
      price: 150,
      param1: param2,
      param2: param3
    },
    // Додайте більше замовлень, якщо потрібно
  ];
  return (
    <div className="order-container">
      {orders.map((order, index) => (
        <OrderCard key={index} order={order} />
      ))}
    </div>
  )
}

export default Order