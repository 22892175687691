import React from 'react'


function OrderCard({ order }) {
  return (
    <div className="order-card">
      <div className="order-info">
        <h2>Замовлення</h2>
        <p><strong>Ім'я:</strong> {order.firstName}</p>
        <p><strong>Прізвище:</strong> {order.lastName}</p>
        <p><strong>По-батькові:</strong> {order.middleName}</p>
        <p><strong>Назва предмету:</strong> {order.subject}</p>
        <p><strong>Ціна:</strong> {order.price} грн</p>
        <p><strong>Параметр 1:</strong> {order.param1}</p>
        <p><strong>Параметр 2:</strong> {order.param2}</p>
      </div>
    </div>
  )
}

export default OrderCard