import React from 'react';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from '../../firebaseConfig';
import { doc, setDoc } from 'firebase/firestore';

function Login({ onLogin }) {



  async function handleSubmit(e){
    e.preventDefault();
    const formData = new FormData(e.target)
    const {email, password} = Object.fromEntries(formData)
    try {
      await signInWithEmailAndPassword(auth, email, password).then((userCredential) => {
      })
        
        onLogin();
        console.log("All good")
    } catch (error) {
      console.log("Неправильний логін або пароль")
        console.log(error)
        console.error(error.message)
    }    
  
};
    
    return (
      <div className="auth-container">
        <h2>Авторизація</h2>
        <form onSubmit={handleSubmit}>
          <label>
            Email:
            <input type="email" name="email" required />
          </label>
          <label>
            Пароль:
            <input type="password" name="password" required />
          </label>
          <button type="submit">Увійти</button>
        </form>
      </div>
    );
  }
  
  function Register({ onRegister }) {
    async function handleSubmit(e){
        e.preventDefault();
        const formData = new FormData(e.target)
        const {name, surname, email, password} = Object.fromEntries(formData)
        try {
            const res = await createUserWithEmailAndPassword(auth, email, password)
            await setDoc(doc(db,'users',res.user.uid),{
                username: name,
                surname: surname,
                email,
                id: res.user.uid,
                lastnamee: '',
                friends: [],
                age: '',
                img:""
            });

            await setDoc(doc(db,'userHistory', res.user.uid),{
                oldlessons: [],
                newlessons: []
            });
            const userToken = {
              id: res.user.uid,
              email: email,
              password: password
            };
            
            localStorage.setItem("user", JSON.stringify(userToken));

            console.log("All good")
        } catch (error) {
            console.log(error)
            console.error(error.message)
        }    
      
    };
  
    return (
      <div className="auth-container">
        <h2>Реєстрація</h2>
        <form onSubmit={handleSubmit}>
          <label>
            Ім'я:
            <input type="text" name="name" required />
          </label>
          <label>
            Прізвище:
            <input type="text" name="surname" required />
          </label>
          <label>
            Email:
            <input type="email" name="email" required />
          </label>
          <label>
            Пароль:
            <input type="password" name="password" required />
          </label>
          <button type="submit">Зареєструватися</button>
        </form>
      </div>
    );
  }

export { Login, Register };