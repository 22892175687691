import React from 'react';
import { useNavigate } from 'react-router-dom';

function SmallCard({ title, img, subject, description, link, tema, priceSolo, priceGroup }){

  const navigate = useNavigate();
    const soloLesson = async ()=>{
      navigate(`/solo/${tema}/${title}/longlesson`);
    }

    const groupLesson = async ()=>{
      navigate(`/group/${tema}/${title}/longlesson`);
    }


  return (
    <div className="small-card">
      <h2>{title}</h2>
      <img src={img} alt={title} />
      <h4>{subject}</h4>
      <p>{description}</p>
      <div className="button-container">
      <button onClick={soloLesson}>Заняття для одного</button>
      <p>Ціна за урок: {priceSolo} </p> <br/>
      <button onClick={groupLesson}>Заняття для групи</button>
      <p>Ціна за урок: {priceGroup} *Данна ціна змінюється в залежності від розміру группи </p><br/>
      </div>
      {/* <a href={link} class="nav-link">Вибрати конкретний урок</a> */}
    </div>
  );
};

export default SmallCard;