import { onAuthStateChanged } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { auth, db } from '../../firebaseConfig';
import { arrayUnion, collection, doc, getDoc, getDocs, query, updateDoc, where } from 'firebase/firestore';


function BuyLesson() {
  const [formData, setFormData] = useState({
    study: '',
    tema: '',
    email: '',
    name: '',
    groupSizeof: 1,
    groupSizeto: 1,
    time: '',
    timeGroup: '',
    lessonCount: 1,
    lessonDuration: '1',
  });

  const [studiee, setStudy] = useState([])
  const [temaa, setTemaa] = useState([])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleChange321 = async (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    try{
      const selectedValue = e.target.value;
      const temaref = collection(db,'tema')
      const q = query(temaref, where("study", "==", selectedValue));
      const querySnapshot = await getDocs(q);
      
      if(querySnapshot){
        let a = []
        querySnapshot.forEach((docs)=>{
          a.push(docs.data())
        })
        setTemaa(a)
      }
      
    }catch(err){
      console.log(err)
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();


    try {
      // Отримуємо посилання на документ
      const docRef = doc(db, 'group', "users");
      // Оновлюємо документ, додаючи новий елемент у масив
      await updateDoc(docRef, {
        
        students: arrayUnion({ ...formData})
        
      });
      alert("Ви успішно подали заявку, скоро з вами зв'яжуться");
      console.log('Дані успішно додані в масив');
    } catch (error) {
      console.error('Помилка додавання даних у масив: ', error);
    }
    // Обробка відправки даних
    console.log(formData);
  };

  const getStudy = async ()=>{
    try{
      const q = doc(db,'lessons', "study");
      const querySnapshot = await getDoc(q);
      const dataa = querySnapshot.data().studies
      setStudy(dataa)
    }catch(err){
      console.log(err)
    }
  }

  useEffect(() => {
    getUser();
    getStudy();
  }, []);

  const getUser = async () => {
    const unSub = onAuthStateChanged(auth, async (user) => {
      if (user == null) {
        return
      } else {
        try {
          const temaref2 = collection(db, 'users');
          const qq = query(temaref2, where('id', '==', user.uid));
          const querySnapshot2 = await getDocs(qq);

          if (querySnapshot2) {
            console.log(querySnapshot2.docs[0].data().email);
            setFormData({ ...formData, email: querySnapshot2.docs[0].data().email, name: querySnapshot2.docs[0].data().username });
          }
        } catch (err) {
          console.log(err);
        }
      }
    });
  };

  return (
    <div className="group-form-container">
      <form onSubmit={handleSubmit} className="group-form">
      <div className="form-group">
          <label>Предмет:</label>
          <select
            name="study"
            value={formData.study}
            onChange={handleChange321}
            required
          >
            {studiee.map(size => (
              <option key={size.name} value={size.name}>{size.name}</option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>Класс:</label>
          <select
            name="tema"
            value={formData.tema}
            onChange={handleChange}
            required
          >
            {temaa.map(size => (
              <option key={size.tema} value={size.tema}>{size.tema}</option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>Пошта:</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Ім'я:</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Розмір групи від:</label>
          <select
            name="groupSizeof"
            value={formData.groupSizeof}
            onChange={handleChange}
            required
          >
            {[1, 2, 3, 4, 5].map(size => (
              <option key={size} value={size}>{size}</option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>Розмір групи до:</label>
          <select
            name="groupSizeto"
            value={formData.groupSizeto}
            onChange={handleChange}
            required
          >
            {[1, 2, 3, 4, 5].map(size => (
              <option key={size} value={size}>{size}</option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>Бажаний час для початку заняття (год:хв - год:хв):</label>
          <input
            type="text"
            name="time"
            value={formData.time}
            onChange={handleChange}
            placeholder="e.g., 09:00 - 17:00"
            required
          />
        </div>
        <div className="form-group">
          <label>Країна з якої ви будете займатись (Це для переведення часу):</label>
          <input
            type="text"
            name="timeGroup"
            value={formData.timeGroup}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Кількість бажаних занять в неділю:</label>
          <select
            name="lessonCount"
            value={formData.lessonCount}
            onChange={handleChange}
            required
          >
            {[1, 2, 3, 4, 5, 6, 7].map(count => (
              <option key={count} value={count}>{count}</option>
            ))}
            <option value="one-time">Одне заняття не на постійній основі</option>
          </select>
        </div>
        <div className="form-group">
          <label>Тривалість заняття:</label>
          <select
            name="lessonDuration"
            value={formData.lessonDuration}
            onChange={handleChange}
            required
          >
            <option value="1">1 година</option>
            <option value="2">2 години</option>
          </select>
        </div>
        <button type="submit">Стати в чергу для пошуку групи</button>
      </form>
    </div>
  );
}

export default BuyLesson;